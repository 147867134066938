import * as React from "react";
import { get } from "lodash";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { Header, Image, Grid, Card, List, Container, Feed, Segment, Comment, Label, Button, Rail, GridColumn } from "semantic-ui-react";
import {withLayout, LayoutProps} from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";
import SectionTitle from "../components/SectionTitle";

interface JobPageProps extends LayoutProps {
  data: {
    post: MarkdownRemark;
  };
}

const JobPage = (props: JobPageProps) => {
  const { frontmatter, fields: { slug }, html } = props.data.post;
  const tags = props.data.post.frontmatter.tags
    .map((tag) => <Label key={tag}><Link to={`/jobboard/tags/${tag}/`}>{tag}</Link></Label>);
  const hostname = new URL(frontmatter.url).hostname;
  const image = get(frontmatter, "image.children.0.fixed", {});

  return (
    <Container text style={{marginTop: "16px"}}>
      <Grid>
        <GridColumn width={3} computer={3} mobile={16} >
          <Image
            size="small"
            srcSet={image.srcSet}
            href={slug}
            as="a"
          />
        </GridColumn>
        <GridColumn width={13} computer={13} mobile={16}>
        <div>
          {/* Title */}
          <SectionTitle
            title={frontmatter.organization}
            subtitle={frontmatter.jobTitle}
            />
          </div>

          {/* Apply Button - desktop */}
          <a href={frontmatter.url} target="_blank" className="mobile hidden">
            <Button primary >
              Apply at {hostname}
            </Button>
          </a>

          {/* Apply Button - mobile*/}
          <a href={frontmatter.url} target="_blank" className="mobile only">
            <Button
              primary
              style={{marginTop: 16}}
            >
              Apply at {hostname}
            </Button>
          </a>

          {/* Content */}
          <Segment vertical
            style={{ border: "none" }}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />

          {/* Apply Button - bottom */}
          <a href={frontmatter.url} target="_blank">
            <Button primary style={{marginBottom: "16px"}}>
              Apply at {hostname}
            </Button>
          </a>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export default withLayout(JobPage);

export const pageQuery = graphql`
query TemplateJob($slug: String!) {
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    excerpt
    fields {
      slug
    }
    frontmatter {
      organization
      jobTitle
      location
      url
      tags
      image {
        children {
          ... on ImageSharp {
            fixed(width: 120) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
}
`;
